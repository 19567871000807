<template>
  <BOverlay
    variant="light"
    :show="loading.detail"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <BCard>
      <div class="flex mb-3">
        <BButton
          variant="primary"
          size="sm"
          class="rounded-lg mr-2"
          @click="handleBack"
        >
          <b-icon-chevron-left />
        </BButton>
        <span class="text-[24px] font-semibold my-auto text-black">Buat Campaign - Blast Email</span>
      </div>
      <BButton
        v-for="btn in buttonData"
        :key="btn.id"
        style="border-radius: 25px"
        class="mr-1"
        :variant="activeBtn === btn.id ? 'primary' : 'outline-primary'"
        @click="handleActiveButton(btn.id)"
      >
        {{ btn.label }}
      </BButton>
      <ValidationObserver #default="{ invalid }">
        <div class="d-flex justify-content-end mb-1">
          <BButton
            class="rounded-lg"
            :variant="invalid || loading.draft ? 'outline-secondary' : 'outline-primary'"
            :disabled="invalid || loading.draft"
            size="sm"
            @click="prevent=onDraft()"
          >
            <div class="flex items-center">
              <BSpinner
                v-if="loading.draft"
                variant="primary"
                small
                class="mr-1"
              />
              <span>Simpan Draft</span>
            </div>
          </BButton>
          <BButton
            class="rounded-lg mx-1"
            :variant="invalid ? 'outline-secondary' : 'outline-primary'"
            :disabled="invalid "
            size="sm"
            @click="$bvModal.show('modal-testing')"
          >
            <div class="flex items-center">
              <span class="k-programming-arrow w-14 text-2xl" />
              <span>Testing Dulu</span>
            </div>
          </BButton>
          <BButton
            class="rounded-lg"
            :variant="invalid ? 'secondary' : 'primary'"
            :disabled="invalid"
            size="sm"
            @click.prevent="$bvModal.show('modal-publish')"
          >
            <div class="flex items-center">
              <span class="k-send-2 w-14 text-2xl" />
              <span>Publish Sekarang</span>
            </div>
          </BButton>
        </div>
        <BRow>
          <BCol :lg="activeBtn === 1 ? 4 : 6">
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Email Pengirim</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <v-select
                  v-model="email"
                  label="email"
                  :options="optionSenderEmails"
                  :reduce="option => option"
                  placeholder="Ketik untuk mencari..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol :lg="activeBtn === 1 ? 4 : 6">
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Target Pengguna</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Target pengguna"
                rules="required"
              >
                <v-select
                  v-model="target"
                  label="label"
                  multiple
                  :options="optionsTarget"
                  :reduce="option => option"
                  placeholder="Ketik untuk mencari..."
                  @input="handleSelectedTarget"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <div class="flex items-center">
                      <BImg
                        :src="option.icon"
                        width="25"
                        class="mr-[5px]"
                      />
                      <span class="text-black capitalize">{{ option.label }} ({{ option.user_count }})</span>
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            v-if="activeBtn === 1"
            lg="4"
          >
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Pilihan Template</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Pilihan Template"
                rules="required"
              >
                <v-select
                  v-model="template"
                  label="label"
                  :options="optionsTemplates"
                  :reduce="option => option"
                  placeholder="Ketik untuk mencari..."
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <span :id="String('popover' + option.label)">{{ option.label }}</span>
                    <BPopover
                      v-for="(item, idx) in optionsTemplates"
                      :key="idx"
                      :target="String('popover' + item.label)"
                      triggers="hover"
                      placement="topleft"
                    >
                      <img
                        :src="item.image"
                        alt="Komerce"
                        width="400"
                        height="400"
                      >
                    </BPopover>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
        </BRow>
        <BRow>
          <BCol lg="12">
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Judul Campaign</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Judul Campaign"
                rules="required"
              >
                <BFormInput
                  v-model="title"
                  placeholder="Silahkan isi judul campaign"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
        </BRow>
        <BRow>
          <BCol lg="12">
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="text-md font-medium">Isi Campaign</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Isi Campaign"
                rules="required"
              >
                <quill-editor
                  v-model="content"
                  :options="editorOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
        </BRow>
      </ValidationObserver>
      <ModalTesting :payload="payload" />
      <ModalPublish
        :payload="payload"
        :email-sender="email"
        :title="title"
        :total-targets="totalTargets"
      />
      <ModalWarning />
    </BCard>
  </BOverlay>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import { newAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import isEmpty from 'lodash/isEmpty'
import ModalTesting from './ModalTesting.vue'
import ModalPublish from './ModalPublish.vue'
import ModalWarning from './ModalWarning.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    quillEditor,
    ModalTesting,
    ModalPublish,
    ModalWarning,
  },
  data() {
    return {
      activeBtn: 1,
      required,
      buttonData: [
        { id: 1, label: 'Email Template' },
        { id: 2, label: 'Email Standar' },
      ],
      email: '',
      template: '',
      alertError,
      checkTarget: false,
      alertSuccess,
      target: [],
      optionsTarget: [],
      optionsTemplates: [],
      optionSenderEmails: [],
      totalTargets: 0,
      title: '',
      isEmpty,
      idCampaign: this.$route.params.id,
      detailCampaign: {},
      loading: {
        info: false,
        draft: false,
        detail: false,
      },
      content: '',
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            ['link'], ['image'],
          ],
        },
      },
    }
  },
  computed: {
    payload() {
      const payload = {
        sender_email: this.email,
        service_type: Array.isArray(this.target) ? this.target.map(item => item.label) : this.target,
        title: this.title,
        content: this.content,
      }

      if (this.idCampaign) {
        payload.id = Number(this.idCampaign)
        if (this.activeBtn === 1) {
          payload.chosen_template = this.template
        }
      } else if (this.idCampaign === undefined && this.activeBtn === 1) {
        payload.chosen_template = this.template?.label
        payload.service_type = this.target.map(item => item.label)
      }

      return Object.keys(payload).length > 0 ? payload : ''
    },
  },
  mounted() {
    this.getInfo()
    if (this.idCampaign !== undefined) {
      this.getDetail()
    }
  },
  methods: {
    async getDetail() {
      this.loading.detail = true
      const url = `/sender/api/v1/campaign/get-detail/${this.idCampaign}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.detailCampaign = data
          this.content = data.content
          this.title = data.title
          this.email = data.sender_email
          this.template = data.chosen_template

          this.loading.detail = false
        })
        .catch(() => {
          this.loading.detail = false
          this.alertError('Gagal memuat data detail campaign')
        })
    },
    async getInfo() {
      this.loading.info = true
      const url = '/sender/api/v1/email/get-info'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          const imageMapping = {
            Komerce: 'https://storage.googleapis.com/komerce/assets/illustration/Preview-email.png',
          }

          const templates = data.choice_template.map(item => ({
            label: item,
            image: imageMapping[item],
          }))

          const combinedArray = Object.keys(data.count_user).map(key => ({
            icon: data.logo_product[key],
            label: key,
            user_count: data.count_user[key],
          }))

          const filteredCombibeArray = combinedArray.filter(item => item.label !== 'komerce')

          this.optionsTarget = filteredCombibeArray
          this.optionsTemplates = templates
          this.optionSenderEmails = data.sender_email
        })
    },
    async onDraft() {
      this.loading.draft = true
      const url = '/sender/api/v1/email/save-draft'
      await newAxiosIns.post(url, this.payload)
        .then(() => {
          this.alertSuccess('Berhasil draft email')
          this.$router.push('/notification')
          this.loading.draft = false
        })
        .catch(() => {
          this.alertError('Gagal blast email')
          this.loading.draft = false
        })
    },
    handleActiveButton(id) {
      this.activeBtn = id
    },
    handleBack() {
      if (this.email === ''
      && isEmpty(this.target)
      && this.template.label === undefined
      && this.title === ''
      && this.content === '') {
        this.$router.go(-1)
      } else {
        this.$bvModal.show('modal-warning')
      }
    },
    handleActiveTemplate({ label }) {
      this.template = { label }
    },
    handleSelectedTarget(value) {
      const totalTarget = value.reduce((total, item) => total + item.user_count, 0)
      this.totalTargets = totalTarget
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
@import '~quill/dist/quill.snow.css';

.vs__dropdown-menu {
  max-height: 230px;
}
.dropdownitem {
  .btn {
    display: flex;
    justify-content: space-between;
  }
}
</style>
