<template>
  <BModal
    id="modal-testing"
    ref="modal-testing"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <div class="my-2">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
    </div>
    <h4 class="text-black text-center">
      Testing
    </h4>
    <p class="text-center text-black">
      Silahkan isi kolom berikut dengan alamat email yang ingin dijadikan tujuan testing, kemudian klik Kirim!
    </p>
    <ValidationObserver #default="{ invalid }">
      <BRow>
        <BCol lg="12">
          <BFormGroup>
            <template #label>
              <div class="text-black flex items-center">
                <span class="text-md font-normal">Email Testing</span>
                <span class="text-primary">*</span>
              </div>
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Email Testing"
              :rules="{ required: true, email: true, regex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ }"
            >
              <BFormInput
                v-model="emailTesting"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow class="flex justify-center mb-2">
        <BCol>
          <BButton
            class="rounded-lg"
            variant="outline-primary"
            block
            @click="$bvModal.hide('modal-testing')"
          >
            Batal
          </BButton>
        </BCol>
        <BCol>
          <BButton
            class="rounded-lg"
            :variant="invalid ? 'secondary' : 'primary'"
            :disabled="invalid"
            block
            @click.prevent="onTesting()"
          >
            <BSpinner
              v-if="loading"
              variant="light"
              small
              class="mr-1"
            />
            Kirim
          </BButton>
        </BCol>
      </BRow>
    </ValidationObserver>
  </BModal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { newAxiosIns } from '@/libs/axios'
import { required, email, regex } from '@validations'
import { alertError, alertSuccess } from '@toast'

export default {
  components: { ValidationObserver, ValidationProvider },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      email,
      regex,
      loading: false,
      emailTesting: '',
      alertError,
      alertSuccess,
    }
  },
  methods: {
    async onTesting() {
      const newPaload = {
        ...this.payload,
        target_email: this.emailTesting,
      }
      this.loading = true
      const url = '/sender/api/v1/email/test-send-email'
      await newAxiosIns.post(url, newPaload)
        .then(async () => {
          this.loading = false
          this.$bvModal.hide('modal-testing')
          this.emailTesting = ''
          this.alertSuccess('Email testing berhasil dikirim, silahkan cek email')
        })
        .catch(err => {
          this.loading = false
          this.alertError('Gagl kirim email testing')
        })
    },
  },
}
</script>
