var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BOverlay',{attrs:{"variant":"light","show":_vm.loading.detail,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('BCard',[_c('div',{staticClass:"flex mb-3"},[_c('BButton',{staticClass:"rounded-lg mr-2",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.handleBack}},[_c('b-icon-chevron-left')],1),_c('span',{staticClass:"text-[24px] font-semibold my-auto text-black"},[_vm._v("Buat Campaign - Blast Email")])],1),_vm._l((_vm.buttonData),function(btn){return _c('BButton',{key:btn.id,staticClass:"mr-1",staticStyle:{"border-radius":"25px"},attrs:{"variant":_vm.activeBtn === btn.id ? 'primary' : 'outline-primary'},on:{"click":function($event){return _vm.handleActiveButton(btn.id)}}},[_vm._v(" "+_vm._s(btn.label)+" ")])}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('BButton',{staticClass:"rounded-lg",attrs:{"variant":invalid || _vm.loading.draft ? 'outline-secondary' : 'outline-primary',"disabled":invalid || _vm.loading.draft,"size":"sm"},on:{"click":function($event){_vm.prevent=_vm.onDraft()}}},[_c('div',{staticClass:"flex items-center"},[(_vm.loading.draft)?_c('BSpinner',{staticClass:"mr-1",attrs:{"variant":"primary","small":""}}):_vm._e(),_c('span',[_vm._v("Simpan Draft")])],1)]),_c('BButton',{staticClass:"rounded-lg mx-1",attrs:{"variant":invalid ? 'outline-secondary' : 'outline-primary',"disabled":invalid,"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show('modal-testing')}}},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"k-programming-arrow w-14 text-2xl"}),_c('span',[_vm._v("Testing Dulu")])])]),_c('BButton',{staticClass:"rounded-lg",attrs:{"variant":invalid ? 'secondary' : 'primary',"disabled":invalid,"size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.show('modal-publish')}}},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"k-send-2 w-14 text-2xl"}),_c('span',[_vm._v("Publish Sekarang")])])])],1),_c('BRow',[_c('BCol',{attrs:{"lg":_vm.activeBtn === 1 ? 4 : 6}},[_c('BFormGroup',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-black flex items-center"},[_c('span',{staticClass:"text-md font-medium"},[_vm._v("Email Pengirim")]),_c('span',{staticClass:"text-primary"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"email","options":_vm.optionSenderEmails,"reduce":function (option) { return option; },"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('BCol',{attrs:{"lg":_vm.activeBtn === 1 ? 4 : 6}},[_c('BFormGroup',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-black flex items-center"},[_c('span',{staticClass:"text-md font-medium"},[_vm._v("Target Pengguna")]),_c('span',{staticClass:"text-primary"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Target pengguna","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"label","multiple":"","options":_vm.optionsTarget,"reduce":function (option) { return option; },"placeholder":"Ketik untuk mencari..."},on:{"input":_vm.handleSelectedTarget},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"flex items-center"},[_c('BImg',{staticClass:"mr-[5px]",attrs:{"src":option.icon,"width":"25"}}),_c('span',{staticClass:"text-black capitalize"},[_vm._v(_vm._s(option.label)+" ("+_vm._s(option.user_count)+")")])],1)]}}],null,true),model:{value:(_vm.target),callback:function ($$v) {_vm.target=$$v},expression:"target"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.activeBtn === 1)?_c('BCol',{attrs:{"lg":"4"}},[_c('BFormGroup',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-black flex items-center"},[_c('span',{staticClass:"text-md font-medium"},[_vm._v("Pilihan Template")]),_c('span',{staticClass:"text-primary"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Pilihan Template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"label","options":_vm.optionsTemplates,"reduce":function (option) { return option; },"placeholder":"Ketik untuk mencari..."},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',{attrs:{"id":String('popover' + option.label)}},[_vm._v(_vm._s(option.label))]),_vm._l((_vm.optionsTemplates),function(item,idx){return _c('BPopover',{key:idx,attrs:{"target":String('popover' + item.label),"triggers":"hover","placement":"topleft"}},[_c('img',{attrs:{"src":item.image,"alt":"Komerce","width":"400","height":"400"}})])})]}}],null,true),model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1),_c('BRow',[_c('BCol',{attrs:{"lg":"12"}},[_c('BFormGroup',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-black flex items-center"},[_c('span',{staticClass:"text-md font-medium"},[_vm._v("Judul Campaign")]),_c('span',{staticClass:"text-primary"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Judul Campaign","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormInput',{attrs:{"placeholder":"Silahkan isi judul campaign","state":errors.length > 0 ? false:null},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('BRow',[_c('BCol',{attrs:{"lg":"12"}},[_c('BFormGroup',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-black flex items-center"},[_c('span',{staticClass:"text-md font-medium"},[_vm._v("Isi Campaign")]),_c('span',{staticClass:"text-primary"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Isi Campaign","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.editorOptions},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)]}}])}),_c('ModalTesting',{attrs:{"payload":_vm.payload}}),_c('ModalPublish',{attrs:{"payload":_vm.payload,"email-sender":_vm.email,"title":_vm.title,"total-targets":_vm.totalTargets}}),_c('ModalWarning')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }